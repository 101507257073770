.App {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.App h1,h2,h3,h4,h5,h6,p {
  margin: 0;
  padding: 0;
}

.App ul,ol {
  margin: 0px;
}

body,button {
  font-family: Roboto;
}

.youtube-icon {
  background-image: url('./assets/img/youtube.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.youtube-icon:hover {
  background-image: url('./assets/img/youtube_color.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.whatsapp-icon {
  background-image: url('./assets/img/whatsapp.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.whatsapp-icon:hover {
  background-image: url('./assets/img/whatsapp_color.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
